import React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
} from "@fluentui/react";

export interface ValidationDialogProps {
  title: string;
  body: string;
  isHidden: boolean;
  toggle: Function;
}

const ValidationDialog: React.FC<ValidationDialogProps> = ({
  title,
  body,
  isHidden,
  toggle,
}) => {
  const _closeDialog = () => toggle(!isHidden);

  return (
    <Dialog
      hidden={isHidden}
      onDismiss={_closeDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
        subText: body,
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
      }}
    >
      <DialogFooter>
        <PrimaryButton onClick={_closeDialog} text="Send" />
      </DialogFooter>
    </Dialog>
  );
};

export default ValidationDialog;
