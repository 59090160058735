import React, { Fragment, useState } from "react";
import { useStoreActions, Actions } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import { DefaultButton, IIconProps } from "@fluentui/react";
import { Navigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Common_i18n_keys } from "../i18n/models/Common.i18n";

export interface SignOutProps {
  styles: React.CSSProperties;
}

const SignOut: React.FC<SignOutProps> = ({ styles }) => {
  const deleteUser = useStoreActions(
    (actions: Actions<RootState>) => actions.root.deleteUser
  );
  const [navigate, setNavigate] = useState({ go: false, to: "/" });
  const signOutIcon: IIconProps = { iconName: "SignOut" };
  const { t } = useTranslation();

  if (navigate.go) return <Navigate to={navigate.to} />;

  return (
    <Fragment>
      <DefaultButton
        text={t(Common_i18n_keys.signOut)}
        style={styles}
        iconProps={signOutIcon}
        onClick={() => {
          deleteUser();
          setNavigate({ ...navigate, go: true });
        }}
      ></DefaultButton>
    </Fragment>
  );
};

export default SignOut;
