import React, { Fragment, useEffect, useState } from "react";
import {
  Stack,
  Dropdown,
  Separator,
  PrimaryButton,
  DefaultButton,
  IDropdownOption,
  DatePicker,
  IDatePickerStrings,
} from "@fluentui/react";
import ValidationDialog from "./ValidationDialog";
import Results from "./Results";
import { useStoreActions, Actions, useStoreState, State } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import { Navigate } from "react-router";
import moment from "../services/moment";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";
import { Search_i18n_Keys } from "../i18n/models/Search.i10n";
import { Common_i18n_keys } from "../i18n/models/Common.i18n";
import { Slot } from "../types/Booking/Slot";

export interface SearchProps {}

const Search: React.FC<SearchProps> = () => {
  const [selected, setSelected] = useState({
    specialityId: 0,
    locationId: 0,
    practitionerId: "*",
    date: new Date(),
    serviceType: "*",
  });

  const getEspecialities = useStoreActions(
    (state: Actions<RootState>) => state.root.getEspecialities
  );
  const getLocations = useStoreActions(
    (state: Actions<RootState>) => state.root.getLocations
  );
  const getPractitioners = useStoreActions(
    (state: Actions<RootState>) => state.root.getPartitioners
  );
  const getSlots = useStoreActions(
    (state: Actions<RootState>) => state.root.getSlots
  );
  const setSlots = useStoreActions(
    (state: Actions<RootState>) => state.root.setSlots
  );
  const setEspeciality = useStoreActions(
    (state: Actions<RootState>) => state.root.setEspeciality
  );
  const getServiceTypes = useStoreActions(
    (state: Actions<RootState>) => state.root.getServiceTypes
  );
  const setServiceType = useStoreActions(
    (actions: Actions<RootState>) => actions.root.setServiceType
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (especialitiesOptions.length === 0) {
      getEspecialities({});
    }
    setSlots([] as Slot[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [navigate, setNavigate] = useState({ go: false, to: "" });

  const DayPickerStrings: IDatePickerStrings = {
    months: moment.months(),

    shortMonths: moment.monthsShort(),

    days: moment.weekdays(),

    shortDays: moment.weekdaysShort(),

    goToToday: "Hoy",
    prevMonthAriaLabel: "Mes anterior",
    nextMonthAriaLabel: "Mes próximo",
    prevYearAriaLabel: "Año anterior",
    nextYearAriaLabel: "Año próximo",
    closeButtonAriaLabel: "Cerrar",

    isRequiredErrorMessage: "Seleccione una fecha",

    invalidInputErrorMessage: "Fecha no válida",
  };

  let especialitiesOptions: IDropdownOption[] = useStoreState(
    (state: State<RootState>) => state.root.especialities
  ).map((item) => {
    return {
      key: item.id,
      text: item.name,
    };
  });

  let locationsOptions: IDropdownOption[] = useStoreState(
    (state: State<RootState>) => state.root.locations
  ).map((item) => {
    return {
      key: item.id,
      text: item.name,
    };
  });
  locationsOptions.unshift({
    key: 0,
    text: t(Common_i18n_keys.all),
    isSelected: true,
  });

  let practitionersOptions: IDropdownOption[] = useStoreState(
    (state: State<RootState>) => state.root.partitioners
  ).map((item) => {
    return {
      key: item.id,
      text: item.name,
    };
  });
  practitionersOptions.unshift({
    key: "*",
    text: t(Common_i18n_keys.all),
    isSelected: true,
  });

  let serviceTypesOptions: IDropdownOption[] = useStoreState(
    (state: State<RootState>) => state.root.serviceTypes
  ).map((item) => {
    return {
      key: item.key,
      text: item.value,
    };
  });

  const goBack = () => {
    setNavigate({ go: true, to: "/" });
  };

  if (navigate.go) return <Navigate to={navigate.to} />;

  return (
    <Fragment>
      <div className="ms-Grid-col ms-sm10 ms-smOffset1 ms-md3 ms-lg3 ms-mdOffset1 ms-mdOffset3 ms-xxxlOffset2 ms-depth-8 panelflotante info-back">
        <Stack tokens={{ childrenGap: 5 }} style={{ padding: "10px" }}>
          <Logo />
          <h4>Busque el servicio que desea agendar</h4>

          <Dropdown
            label={t(Common_i18n_keys.speciality)}
            title="especiality"
            placeholder={t(Common_i18n_keys.specialityPlaceholder)}
            options={especialitiesOptions}
            onChange={(e, o) => {
              const id = o ? Number(o.key) : 0;
              setSelected({ ...selected, specialityId: id });
              setEspeciality(id);
              getPractitioners({ specialityId: id });
              getLocations(id);
              getServiceTypes(id);
            }}
            required
          />

          <Dropdown
            label={t(Common_i18n_keys.serviceType)}
            title="serviceType"
            placeholder={t(Common_i18n_keys.serviceTypePlaceholder)}
            options={serviceTypesOptions}
            onChange={(e, o) => {
              const id = o ? o.key.toString() : "*";
              setSelected({ ...selected, serviceType: id });
              setServiceType(id);
            }}
            required
          />

          <DatePicker
            ariaLabel={t(Search_i18n_Keys.requestedDate)}
            label={t(Search_i18n_Keys.requestedDate)}
            isRequired={false}
            allowTextInput={true}
            value={selected.date}
            strings={DayPickerStrings}
            formatDate={(date) => {
              return moment(date).format("L");
            }}
            minDate={new Date()}
            onSelectDate={(date) => {
              if (date) {
                setSelected({ ...selected, date: date });
              }
            }}
          />

          <Dropdown
            label={t(Common_i18n_keys.location)}
            title={t(Common_i18n_keys.location)}
            placeholder={t(Common_i18n_keys.locationPlaceholder)}
            options={locationsOptions}
            disabled={locationsOptions.length > 1 ? false : true}
            onChange={(e, o) => {
              const id = o ? Number(o.key) : 0;
              getPractitioners({
                specialityId: selected.specialityId,
                locationId: id,
              });
              setSelected({ ...selected, locationId: id });
            }}
          />

          <Dropdown
            label={t(Common_i18n_keys.practitioner)}
            title={t(Common_i18n_keys.practitioner)}
            placeholder={t(Common_i18n_keys.practitionerPlaceholder)}
            options={practitionersOptions}
            onChange={(e, o) => {
              const id = o ? o.key.toString() : "*";
              setSelected({ ...selected, practitionerId: id });
            }}
            disabled={practitionersOptions.length > 1 ? false : true}
          />
          <label htmlFor="" className="ms-fontSize-12">
            (*) Los campos marcados son obligatorios
          </label>

          <Separator />

          <div>
            <div style={{ float: "right" }}>
              <PrimaryButton
                text={t(Common_i18n_keys.search)}
                style={{ width: "100px" }}
                disabled={
                  selected.specialityId > 0 && selected.serviceType !== "*"
                    ? false
                    : true
                }
                onClick={() => getSlots(selected)}
              ></PrimaryButton>
            </div>
            <div style={{ float: "left" }}>
              <DefaultButton
                text={t(Common_i18n_keys.goBack)}
                style={{ width: "100px" }}
                onClick={goBack}
              ></DefaultButton>
            </div>
          </div>
        </Stack>
        <div style={{ textAlign: "center", padding: "10px" }}>
          <img style={{ width: "100px" }} src="/logo.png" alt="" />
        </div>
        <ValidationDialog
          title="Ups"
          body={t(Common_i18n_keys.imcompleteFormFields)}
          isHidden={true}
          toggle={() => {}}
        ></ValidationDialog>
      </div>
      <div
        className="ms-Grid-col ms-sm10 ms-smOffset1 ms-mdOffset3 ms-md6 ms-lg7 ms-xxxl5"
        style={{ marginTop: "40px", marginLeft: "20px" }}
      >
        <Results></Results>
      </div>
    </Fragment>
  );
};

export default Search;
