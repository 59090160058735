import React, { useState, useEffect } from "react";
import {
  TextField,
  Stack,
  PrimaryButton,
  Dropdown,
  Separator,
  IDropdownOption,
  Checkbox,
} from "@fluentui/react";
import { Navigate } from "react-router-dom";
import Logo from "./Logo";
import { useStoreState, State, useStoreActions, Actions } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import { useTranslation } from "react-i18next";
import { Login_i18n_Keys } from "../i18n/models/Login.i18n";
import { Register_i18n_Keys } from "../i18n/models/Register.i18n";
import { PasswordResetRequest } from "../types/PatientAuthentication";
export interface RegisterProps {}

const WithoutIdentificationType = 0;
const InitState: PasswordResetRequest = {
  AcceptTermsAndConditions: false,
  AgreeToSendInformation: false,
  IdentificationNumber: "",
  IdentificationType: WithoutIdentificationType,
};

const Register: React.FC<RegisterProps> = () => {
  const [state, setState] = useState<PasswordResetRequest>({
    ...InitState,
  });

  const [NavigateToHome, setNavigateToHome] = useState(false);

  const docTypesOptions: IDropdownOption[] = useStoreState(
    (state: State<RootState>) => state.root.documentTypes
  ).map((item) => {
    return {
      key: item.id,
      text: item.value,
    };
  });

  const resetPatientPassword = useStoreActions(
    (actions: Actions<RootState>) => actions.root.resetPatientPassword
  );
  const setEmailPassword = useStoreActions(
    (actions: Actions<RootState>) => actions.root.setEmailPassword
  );
  const emailPassword = useStoreState(
    (state: State<RootState>) => state.root.emailPassword
  );
  const getDocumentTypes = useStoreActions(
    (actions: Actions<RootState>) => actions.root.getDocumentTypes
  );
  const axiosIsOnRequest = useStoreState(
    (state: State<RootState>) => state.root.axiosIsOnRequest
  );

  const userIsLoggedIn = useStoreState(
    (state: State<RootState>) => state.root.userIsLoggedIn
  );
  const loginError = useStoreState(
    (state: State<RootState>) => state.root.loginError
  );
  const setLoginError = useStoreActions(
    (actions: Actions<RootState>) => actions.root.setLoginError
  );

  const { t } = useTranslation();

  useEffect(() => {
    getDocumentTypes();
    setLoginError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropDocTypeChange = (
    e: React.FormEvent<HTMLDivElement>,
    dropdownOption?: IDropdownOption
  ) => {
    setState((state) => ({
      ...state,
      IdentificationType: dropdownOption
        ? Number(dropdownOption.key.toString())
        : WithoutIdentificationType,
    }));
  };

  const textFieldChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    let target = e.target as HTMLInputElement;
    setState((state) => ({
      ...state,
      IdentificationNumber: target.value ? target.value.trim() : "",
    }));
  };

  const ResetPatientPassword = () => {
    resetPatientPassword({ ...state });
  };

  if (NavigateToHome) return <Navigate to="/" />;

  if (userIsLoggedIn) return <Navigate to="/myappointments" />;

  const RequestTempPassword =
    emailPassword === null ? (
      <div>
        <h4>{t(Register_i18n_Keys.header)}</h4>
        <span style={{ color: "red" }}>{loginError}</span>
        <Dropdown
          label={t(Login_i18n_Keys.login_docTypeLabel)}
          title="idDocType"
          options={docTypesOptions}
          onChange={dropDocTypeChange}
        />

        <TextField
          label={t(Login_i18n_Keys.login_docNumberLabel)}
          name="docNumber"
          onChange={textFieldChange}
          required
        />
        <label htmlFor="" className="ms-fontSize-12">
          (*) Los campos marcados son obligatorios
        </label>
        <Separator />
        <Checkbox
          label={t(Register_i18n_Keys.iHaveReadTermsAndConditions)}
          onChange={(ev, val) => {
            setState((state) => ({
              ...state,
              AcceptTermsAndConditions: val ? true : false,
            }));
          }}
        />
        <br />
        <Checkbox
          label={t(Register_i18n_Keys.iAcceptBeenContacted)}
          onChange={(ev, val) => {
            setState((state) => ({
              ...state,
              AgreeToSendInformation: val ? true : false,
            }));
          }}
        />
        <Separator />
        <PrimaryButton
          text={t(Register_i18n_Keys.checkMyData)}
          style={{ width: "100%" }}
          onClick={ResetPatientPassword}
          disabled={
            !state.IdentificationNumber ||
            !state.IdentificationType ||
            !state.AcceptTermsAndConditions ||
            !state.AgreeToSendInformation ||
            axiosIsOnRequest
          }
        ></PrimaryButton>
      </div>
    ) : (
      <div>
        <p>
          {emailPassword === "" ? (
            t(Register_i18n_Keys.missingEmailPassword)
          ) : (
            <React.Fragment>
              {t(Register_i18n_Keys.iHaveReadEmailPassword)}{" "}
              <strong>{emailPassword}</strong>.
            </React.Fragment>
          )}
        </p>
        <Separator />
        <PrimaryButton
          text="Volver al inicio"
          style={{ width: "100%" }}
          onClick={() => {
            setNavigateToHome(true);
            setEmailPassword(null);
          }}
        ></PrimaryButton>
      </div>
    );

  return (
    <div className="ms-Grid-col ms-sm10 ms-smOffset1 ms-md8 ms-lg4 ms-mdOffset2 ms-mdOffset3 ms-depth-8 panelflotante info-back">
      <Stack tokens={{ childrenGap: 5 }} style={{ padding: "10px" }}>
        <Logo />
        {RequestTempPassword}
      </Stack>
      <div style={{ textAlign: "center", padding: "10px" }}>
        <img style={{ width: "120px" }} src="/logo.png" alt="" />
      </div>
    </div>
  );
};

export default Register;
