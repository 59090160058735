import React, { useState, useEffect, Fragment } from "react";
import {
  TextField,
  Stack,
  PrimaryButton,
  Dropdown,
  Separator,
  IDropdownOption,
  Icon,
} from "@fluentui/react";
import { Navigate, Link } from "react-router-dom";
import Logo from "./Logo";
import { useStoreState, State, useStoreActions, Actions } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import { LoginInput } from "../types/User";
import { useTranslation } from "react-i18next";
import { Login_i18n_Keys } from "../i18n/models/Login.i18n";
import { ClientsConfig } from "../types/ClientsConfig";

export interface LoginProps {}

const messageCardiovascular: string =
  "Para usar este servicio Ud debe haber sido atendido en nuestra entidad con antelación. Si es la primera vez y desea reservar una cita debe comunicarse con nuestro callcenter 5922978, correo electrónico consulta.extcx@hccsa.com.co o línea WhatsApp 3133918843. Recuerde que para su atención debe contar con Autorización de La Eps remitida para nuestra institución y Orden Médica de no ser así se toma la cita como particular.";
const templateMessage: string =
  "Para usar este servicio Ud debe haber sido atendido en nuestra entidad con antelación. Si es la primera vez y desea reservar una cita debe comunicarse con nuestro centro de llamadas.";

const Login: React.FunctionComponent<LoginProps> = () => {
  const docTypesOptions: IDropdownOption[] = useStoreState(
    (state: State<RootState>) => state.root?.documentTypes
  )?.map((item) => {
    return {
      key: item.id,
      text: item.value,
    };
  });

  const { t } = useTranslation();

  const userIsLoggedIn = useStoreState(
    (state: State<RootState>) => state.root.userIsLoggedIn
  );
  const axiosIsOnRequest = useStoreState(
    (state: State<RootState>) => state.root.axiosIsOnRequest
  );
  const loginError = useStoreState(
    (state: State<RootState>) => state.root.loginError
  );

  const sendLogin = useStoreActions(
    (actions: Actions<RootState>) => actions.root.sendLogin
  );

  const getDocumentTypes = useStoreActions(
    (actions: Actions<RootState>) => actions.root.getDocumentTypes
  );
  const setLoginError = useStoreActions(
    (actions: Actions<RootState>) => actions.root.setLoginError
  );

  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [clientMessage, setClientMessage] = useState("");

  useEffect(() => {
    getDocumentTypes();
    setLoginError("");

    const { currentClient } = getDataFromClient();
    currentClient && setClientPhoneNumber(currentClient.clientPhoneNumber);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { subdomain, currentClient } = getDataFromClient();

    let message: string = ValidateEntityMessage(
      subdomain.toLocaleLowerCase(),
      clientPhoneNumber
    );
    currentClient && setClientMessage(message);
  }, [clientPhoneNumber]);

  const [login, setLogin] = useState({} as LoginInput);

  const dropDocTypeChange = (
    e: React.FormEvent<HTMLDivElement>,
    o?: IDropdownOption
  ) => {
    setLogin({ ...login, idDocType: o ? Number(o.key) : 0 });
  };

  const textFieldChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    let target = e.target as HTMLInputElement;
    value = value ? value.trim() : "";
    setLogin({
      ...login,
      [target.name]: value,
      [`${target.name}Error`]: value ? "" : "Este campo es requerido",
    });
  };

  const SearchUser = () => {
    sendLogin({ ...login });
  };

  const ValidateEntityMessage = (key: string, phoneNumber: string) => {
    let entity: string = "citas-cardiovascular";
    
    if (key === entity) {
      return messageCardiovascular;
    }

    return `${templateMessage} (Telf:${phoneNumber})`;
  };

  const getDataFromClient = () => {
    const subdomain = window.location.host.split(".")[0];
    const clients = (window as any)._env_CLIENTS as ClientsConfig[];
    const currentClient = clients.find(
      (c) => c.key.toLowerCase() === subdomain.toLowerCase()
    );

    return { subdomain, clients, currentClient };
  };

  if (userIsLoggedIn) return <Navigate to="/myappointments" />;

  return (
    <Fragment>
      <div className="ms-Grid-col ms-sm10 ms-smOffset1 ms-md8 ms-lg4 ms-mdOffset2 ms-mdOffset3 ms-depth-8 panelflotante info-back">
        <Stack tokens={{ childrenGap: 5 }} style={{ padding: "10px" }}>
          <Logo />

          <h4>{t(Login_i18n_Keys.login_header)}</h4>
          <span style={{ color: "red" }}>{loginError}</span>

          <Dropdown
            label={t(Login_i18n_Keys.login_docTypeLabel)}
            title="idDocType"
            options={docTypesOptions}
            onChange={dropDocTypeChange}
          />

          <TextField
            label={t(Login_i18n_Keys.login_docNumberLabel)}
            name="idDocNumber"
            onChange={textFieldChange}
            required
          />

          <TextField
            label={t(Login_i18n_Keys.login_passLabel)}
            type="password"
            name="password"
            onChange={textFieldChange}
            required
          />
          <label htmlFor="" className="ms-fontSize-12">
            (*) Los campos marcados son obligatorios
          </label>
          <Separator />

          <div>
            <div>
              <PrimaryButton
                disabled={
                  login.password === "" ||
                  login.idDocNumber === 0 ||
                  login.idDocType === 0 ||
                  axiosIsOnRequest
                }
                text={t(Login_i18n_Keys.login_enterButton)}
                style={{ width: "100%" }}
                onClick={SearchUser}
              ></PrimaryButton>
            </div>
            <br></br>
            <div style={{ textAlign: "center" }}>
              <Link to="/register">
                {t(Login_i18n_Keys.login_registerLink)}
              </Link>
            </div>
            <br />
            <div className="ms-Grid-row">
              <div
                className="ms-Grid-col ms-sm12 ms-md2"
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  color: "green",
                }}
              >
                <Icon iconName="Info" style={{ fontSize: "40px" }}></Icon>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md10">
                <p>{clientMessage}</p>
              </div>
            </div>
          </div>
        </Stack>
        <div style={{ textAlign: "center", padding: "10px" }}>
          <img style={{ width: "120px" }} src="/logo.png" alt="" />
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
