import React from 'react';
import Login from './components/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import Search from './components/Search';
import store from './store/store';
import { StoreProvider } from 'easy-peasy';
import '../src/App.css';
import MyAppointments from './components/MyAppointments';
import Register from './components/Register';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';

initializeIcons();

interface AppProps {
  error?: any;
}

const App: React.FC<AppProps> = ({ error }) => {
  return (
    <StoreProvider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route
              path='/search'
              element={
                <ProtectedRoute>
                  <Search />
                </ProtectedRoute>
              }
            />
            <Route
              path='/myappointments'
              element={
                <ProtectedRoute>
                  <MyAppointments />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </StoreProvider>
  );
};

export default App;
