import React from "react";
import { Stack } from "@fluentui/react";
import Logo from "./Logo";
import ErrorDetail from "./ErrorDetail";
import { CustomError } from "../types/CustomError";
import store from "../store/store";

interface ErrorBoundaryPros {
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
  info?: React.ErrorInfo;
}

const watchForErrors = (_this: ErrorBoundary) => {
  const storeError = store.getState().root.storeError;
  if (storeError) _this.setState(storeError);
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryPros,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryPros) {
    super(props);
    this.state = {};
    store.subscribe(() => watchForErrors(this));
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ error, info });
  }

  render() {
    if (this.state.error && this.state.info) {
      // You can render any custom fallback UI
      return (
        <div className="ms-Grid-col ms-sm10 ms-smOffset1 ms-md8 ms-lg4 ms-mdOffset2 ms-mdOffset3 ms-depth-8 panelflotante info-back">
          <Stack tokens={{ childrenGap: 5 }} style={{ padding: "10px" }}>
            <Logo />

            <ErrorDetail error={this.state as CustomError} />
          </Stack>
        </div>
      );
    }
    return this.props.children;
  }
}
