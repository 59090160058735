import {
  Stack,
} from "@fluentui/react";
import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import { ClientsConfig } from "../types/ClientsConfig";
import LoadingSeparator from "./LoadingSeparator";

export interface Props {}

const Logo: React.FunctionComponent<Props> = () => {
  const [currentClient, setCurrentClient] = useState({} as ClientsConfig);

  useEffect(() => {
    const clients = (window as any)._env_CLIENTS as ClientsConfig[];
    const subdomain = window.location.host.split(".")[0];

    const client = clients.find(
      (c) => c.key.toLowerCase() === subdomain.toLowerCase()
    );

    if (client) {
      setCurrentClient(client);
    }
  }, []);

  const labelStyles: CSSProperties = {
    fontWeight: "lighter",
    fontSize: "small",
  };
  const headerStyles: CSSProperties = {
    fontWeight: "lighter",
    marginBottom: "0",
    marginTop: "0",
  };

  return (
    <Fragment>
      <Stack horizontal>
        <Stack.Item grow={2}>
          <div style={currentClient.logoStyles}>
            <img
              style={{ width: "150px" }}
              src={currentClient.logoUrl}
              alt={currentClient.clientName}
            />
          </div>
        </Stack.Item>
        <Stack.Item grow={4}>
          <h1 className="ms-fontSize-32" style={headerStyles}>
            Portal de Citas
          </h1>
          <label style={labelStyles}>{currentClient.clientName}</label>
        </Stack.Item>
      </Stack>
      <LoadingSeparator></LoadingSeparator>
    </Fragment>
  );
};

export default Logo;
