import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  TextField,
  Separator,
} from "@fluentui/react";
import { useStoreActions, Actions } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import { AppointmentCancelStatus } from "../types/Cancelling/AppointmentCancelStatus";
import { AppointmentReceived } from "../types/HL7/AppointmentReceived";
import { useTranslation } from "react-i18next";
import { Cancel_i18n_Keys } from "../i18n/models/Cancel.i18n";
import { Common_i18n_keys } from "../i18n/models/Common.i18n";
import { Confirm_i18n_Keys } from "../i18n/models/Confirm.i18n";

export interface CancelConfirmDialogProps {
  appointment: AppointmentReceived;
}

const CancelConfirmDialog: React.FunctionComponent<CancelConfirmDialogProps> = ({
  appointment,
}) => {
  const initReason = appointment.extension.find(
    (ext) => ext.id === "razon-cancelacion"
  );

  const [reason, setReason] = useState(
    initReason ? initReason.valueString : ""
  );

  const cancelAppointment = useStoreActions(
    (actions: Actions<RootState>) => actions.root.cancelAppointment
  );
  const changeAppointmentCancelStatus = useStoreActions(
    (actions: Actions<RootState>) => actions.root.changeAppointmentCancelStatus
  );
  const deleteMyAppointment = useStoreActions(
    (actions: Actions<RootState>) => actions.root.deleteMyAppointment
  );
  const { t } = useTranslation();

  const reasonChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    value = value ? value.trim() : "";
    setReason(value);
  };

  return (
    <Fragment>
      <Dialog
        key={AppointmentCancelStatus.ON_CONFIRM}
        hidden={
          !(appointment.cancellingStatus === AppointmentCancelStatus.ON_CONFIRM)
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Cancel_i18n_Keys.areYouSure),
          subText: t(Cancel_i18n_Keys.introRaison),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      >
        <TextField
          key="reason"
          name="reason"
          multiline={true}
          placeholder={t(Cancel_i18n_Keys.raisonPlaceHolder)}
          onChange={reasonChange}
        ></TextField>
        <Separator></Separator>
        <DialogFooter>
          <PrimaryButton
            disabled={reason === ""}
            onClick={() => {
              let extension = appointment.extension.find(
                (ext) => ext.id === "razon-cancelacion"
              );
              if (!extension) {
                extension = { id: "razon-cancelacion", valueString: "" };
                appointment.extension.push(extension);
              }
              extension.valueString = reason;

              cancelAppointment(appointment);
            }}
            text={t(Cancel_i18n_Keys.yesImSureButton)}
          />
          <DefaultButton
            onClick={() => {
              changeAppointmentCancelStatus({
                id: appointment.id,
                newCancellingStatus: AppointmentCancelStatus.LISTED,
              });
            }}
            text={t(Common_i18n_keys.cancel)}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        key={AppointmentCancelStatus.ON_REQUEST}
        hidden={
          !(appointment.cancellingStatus === AppointmentCancelStatus.ON_REQUEST)
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Cancel_i18n_Keys.sendingCancelRequest),
          subText: t(Cancel_i18n_Keys.waitWhileCancelling),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      ></Dialog>
      <Dialog
        key={AppointmentCancelStatus.CANCELLED}
        hidden={
          !(appointment.cancellingStatus === AppointmentCancelStatus.CANCELLED)
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Cancel_i18n_Keys.appointmentCancelled),
          subText: t(Confirm_i18n_Keys.thanksForUsingOurPortal),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      >
        <Separator></Separator>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              changeAppointmentCancelStatus({
                id: appointment.id,
                newCancellingStatus: AppointmentCancelStatus.LISTED,
              });
              deleteMyAppointment(appointment.id);
            }}
            text={t(Common_i18n_keys.finish)}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        key={AppointmentCancelStatus.DENIED}
        hidden={
          !(appointment.cancellingStatus === AppointmentCancelStatus.DENIED)
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Cancel_i18n_Keys.cancelRequestDenied),
          subText: t(Common_i18n_keys.tryAgain),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      >
        <Separator></Separator>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              changeAppointmentCancelStatus({
                id: appointment.id,
                newCancellingStatus: AppointmentCancelStatus.LISTED,
              });
            }}
            text={t(Common_i18n_keys.close)}
          />
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default CancelConfirmDialog;
