import React from "react";
import { Navigate } from "react-router";
import { useStoreState, State } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import { useLocation } from "react-router-dom";

export interface ProtectedRouteProps {
  children : JSX.Element
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  let location = useLocation()
  const userIsLoggedIn = useStoreState(
    (state: State<RootState>) => state.root.userIsLoggedIn
  );

  if (!userIsLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />
  }
  return children
};

export default ProtectedRoute;
