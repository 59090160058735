import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  TextField,
  Label,
  Separator,
} from "@fluentui/react";
import { Slot } from "../types/Booking/Slot";
import { useStoreActions, Actions, useStoreState, State } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import moment from "../services/moment";
import { SlotBookingStatus } from "../types/Booking/SlotBookingStatus";
import { Navigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Confirm_i18n_Keys } from "../i18n/models/Confirm.i18n";
import { Common_i18n_keys } from "../i18n/models/Common.i18n";

export interface ConfirmBookingProps {
  slot: Slot;
}

const ConfirmBooking: React.FunctionComponent<ConfirmBookingProps> = ({
  slot,
}) => {
  const toggle = useStoreActions(
    (state: Actions<RootState>) => state.root.toggleConfirmPanel
  );

  const booking = useStoreState(
    (state: State<RootState>) => state.root.booking
  );
  const userFullName = useStoreState(
    (state: State<RootState>) => state.root.userFullName
  );
  const sendAppointment = useStoreActions(
    (actions: Actions<RootState>) => actions.root.sendAppointment
  );
  const deleteSlot = useStoreActions(
    (actions: Actions<RootState>) => actions.root.deleteSlot
  );
  const { t } = useTranslation();

  const [navigate, setNavigate] = useState({ go: false, to: "" });

  const [contact, setContact] = useState({
    phone: booking && booking.user ? booking.user.cellphone : "",
    email: booking && booking.user ? booking.user.email : "",
    phoneError: "",
    emailError: "",
  });

  const textChanged = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    let target = e.target as HTMLInputElement;
    if (
      target.name === "email" &&
      value !== "" &&
      !validateEmail(value ? value : "")
    ) {
      setContact({
        ...contact,
        email: value ? value : "",
        emailError: "El email no es válido",
      });
      return;
    }
    setContact({
      ...contact,
      [target.name]: value,
      [`${target.name}Error`]: value !== "" ? "" : "Este campo es requerido",
    });
  };

  const validateEmail = (value: string) => {
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );
  };

  const sendData = () => {
    sendAppointment({ booking, slot, contact, callback: () => {} });
  };

  const goMyAppointments = () => {
    setNavigate({ go: true, to: "/myappointments" });
  };

  if (navigate.go) return <Navigate to={navigate.to} />;

  return (
    <Fragment>
      <Dialog
        key={SlotBookingStatus.ON_CONFIRM}
        hidden={
          !(
            slot.confirmDialogIsVisible &&
            slot.bookingStatus === SlotBookingStatus.ON_CONFIRM
          )
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Confirm_i18n_Keys.Confirm_header),
          subText: t(Confirm_i18n_Keys.intro),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      >
        <Label required={true}>Especialidad</Label>
        <span style={{ fontSize: "" }}>
          {booking.especiality ? booking.especiality.name : ""}
        </span>
        {slot ? (
          <Fragment>
            <Label>Profesional</Label>
            <span>{slot.practitionerName}</span>
            <Label>Lugar</Label>
            <span>{slot.locationName}</span>
            <Label>Fecha y Hora</Label>
            <span>{moment(slot.date).format("LLLL")}</span>
            <Separator></Separator>
          </Fragment>
        ) : (
          ""
        )}
        <Label required={false}>Paciente</Label>
        <span style={{ fontSize: "" }}>{userFullName}</span>
        <TextField
          label="Email"
          type="email"
          name="email"
          onChange={textChanged}
          defaultValue={contact.email}
          required
          errorMessage={contact.emailError}
        />
        <TextField
          label={t(Common_i18n_keys.phoneNumber)}
          type="number"
          name="phone"
          onChange={textChanged}
          defaultValue={contact.phone}
          required
          errorMessage={contact.phoneError}
        />
        <Separator></Separator>
        <DialogFooter>
          <PrimaryButton
            onClick={sendData}
            text={t(Common_i18n_keys.confirm)}
            disabled={
              contact.email === "" ||
              contact.phone === "" ||
              !validateEmail(contact.email)
            }
          />
          <DefaultButton
            onClick={() => toggle(slot.id)}
            text={t(Common_i18n_keys.cancel)}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        key={SlotBookingStatus.ON_REQUEST}
        hidden={
          !(
            slot.confirmDialogIsVisible &&
            slot.bookingStatus === SlotBookingStatus.ON_REQUEST
          )
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Confirm_i18n_Keys.sendingRequest),
          subText: t(Confirm_i18n_Keys.sendingRequestDescription),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      ></Dialog>
      <Dialog
        key={SlotBookingStatus.CONFIRMED}
        hidden={
          !(
            slot.confirmDialogIsVisible &&
            slot.bookingStatus === SlotBookingStatus.CONFIRMED
          )
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Confirm_i18n_Keys.appointmentConfirmed),
          subText: t(Confirm_i18n_Keys.thanksForUsingOurPortal),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      >
        <Separator></Separator>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              deleteSlot(slot.id);
              goMyAppointments();
            }}
            text="Terminar"
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        key={SlotBookingStatus.DENIED}
        hidden={
          !(
            slot.confirmDialogIsVisible &&
            slot.bookingStatus === SlotBookingStatus.DENIED
          )
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t(Confirm_i18n_Keys.requestDenied),
          subText: t(Common_i18n_keys.tryAgain),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        }}
      >
        <Separator></Separator>
        <DialogFooter>
          <DefaultButton
            onClick={() => toggle(slot.id)}
            text={t(Common_i18n_keys.goBack)}
          />
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmBooking;
